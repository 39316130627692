import React from "react";
import "./Projects.css";
import img from "../../Assests/Img/project.webp";
import { motion } from "framer-motion";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

const Projects = () => {
  return (
    <>
      <div className="project-container" id="projects">
        <div className="project-content">
          <div className="PROJECT"> <div className="project-data">
            <motion.h1
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              LAST PROJECT
            </motion.h1>
            <div className="project-data">
              <p>
                Bilco Direct is a family-owned business now in its second
                generation, with over 20 years of experience in providing
                hampers, gifts, and toys to local customers. Starting in 1997,
                they were supplied by Park Hampers in Merseyside and quickly
                became one of their largest wholesale clients.
              </p>
            </div>
          </div>
          <div className="project-img">
            <div className="port-box">
              <div className="port-image">
                <img src={img} alt="" />
              </div>
              <div className="port-content">
                <a href="https://bilcodirect.co.uk/" target="_blank">
                  <i>
                    <FaArrowUpRightFromSquare />
                  </i>
                </a>
              </div>
            </div>
          </div>{" "}</div>
          <div className="project">
          <a href="https://bilcodirect.co.uk/" target="_blank"><button>READ MORE</button></a>
           
        </div>
          
        </div>
        
      </div>
    </>
  );
}

export default Projects