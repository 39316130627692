import React from "react";
import "./Business.css";
import img from "../../Assests/Img/whychooseus.webp";

const Business = () => {
  return (
    <>
      <div className="business-container">
        <div className="business-content">
          <div>
            <h3 className="h1">WHY CHOOSE US</h3>
            <p>
              <b style={{color:"black"}}>Experience:</b> With over a decade of experience, we bring a
              wealth of knowledge and expertise to every project.
            </p>
            <p>
              <b style={{color:"black"}}> Client-Centric Approach:</b> Your vision is our priority. We work closely with you to
              understand your needs and deliver solutions that exceed your
              expectations.
            </p>
            <p>
              <b style={{color:"black"}}>Innovation:</b> We stay ahead of industry trends and leverage
              the latest technologies to provide cutting-edge solutions.
            </p>
            <p>
              {" "}
              <b style={{color:"black"}}>Quality Assurance:</b> Our rigorous testing and quality
              assurance processes ensure that every product we deliver is of the
              highest standard.
            </p>
          </div>
          <div className="business-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Business