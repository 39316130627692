import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./Gototop.css";

const Gototop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const listenToScroll = () => {
    let heightToHidden = 250;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const gotobtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);
  return (
    <>
      {isVisible && (
        <div className="top-btn" onClick={gotobtn}>
          <FaArrowUp />
        </div>
      )}
    </>
  );
};

export default Gototop;
