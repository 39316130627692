import React from "react";
import "./Team.css";
import img1 from "../../Assests/Img/shahg.webp";
import img2 from "../../Assests/Img/kamran.webp";
import img3 from "../../Assests/Img/xakir.webp"
import img4 from "../../Assests/Img/asif.webp";
import img5 from "../../Assests/Img/kashif.webp"
import { motion } from "framer-motion";


const Team = () => {
  return (
    <div>
      <div className="team-container" id="team">
        <div className="team-CONTENT">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 1,
            }}
            className="team-content"
          >
            <h1>THE TEAM OF WINNERS</h1>
            <p>We work hard. We play hard. We do the results.</p>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 1,
            }}
            className="team-row"
          >
            <div className="cards1">
              <div className="facilities-col">
                <img src={img1} alt="" />
                <h2>SHAH KHALID</h2>
                <h3>CTO / Co-Founder</h3>
               
              </div>
            </div>
            <div className="cards2">
              <div className="facilities-col">
                <img src={img2} alt="" />
                <h2>KAMRAN KAMI</h2>
                <h3>Full Stack </h3>
               
              </div>
            </div>
            <div className="cards3">
              <div className="facilities-col">
                <img src={img3} alt="" />
                <h2>ZAKIR</h2>
                <h3>Wordpress</h3>
               
              </div>
            </div>  <div className="cards4">
              <div className="facilities-col">
                <img src={img4} alt="" />
                <h2>Asif Khan</h2>
                <h3>MERN Stack </h3>
               
              </div>
            </div>  <div className="cards3">
              <div className="facilities-col">
                <img src={img5} alt="" />
                <h2>Kashif</h2>
                <h3>ReactJS</h3>
               
              </div>
            </div>
          </motion.div>
          <div>
            <div className="team-data">
              <h1>Since 2009 We Came so far</h1>
              <p>
              Since 2009, M/S Techfas has been delivering top-notch web development, mobile app creation, and Chrome extension services on Upwork and Freelancer.com. Our experienced team excels in building responsive websites, intuitive mobile apps, and powerful browser extensions. We prioritize client satisfaction, leveraging the latest technologies to provide innovative and quality solutions. Partner with us to transform your ideas into digital realities and achieve your business goals.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team