import React from "react";
import "./Services.css";
import {
  FaCode,
  FaBuilding,
  FaReact,
  FaChrome,
  FaMobileAlt,
  FaWordpress,
} from "react-icons/fa";
import { motion } from "framer-motion";

const Services = () => {
  return (
    <>
      <div className="services-content" id="services">
        <div className="services-container">
          <div className="services-text">
            <motion.h2
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              OUR SERVICES
            </motion.h2>
            <p>
              We provide wide range of services, starting with web development services,mobile applications, chrome extensions, automation tolls, API integration, payment system so basically anything that comes in the domain of web development.
            </p>
          </div>

          <div className="services-cards">
            <div className="section-services">
              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaCode className="services-icon" />
                </motion.div>
                <h3>WEB DEVELOPMENT</h3>
                <p>
                  Websites are built using HTML, CSS, and
                  JavaScript (front end) and PHP, Python, or Node.js (back end) with love and precision by out team of experts. No stone is left unturned to get to the goal set by our clients.
                </p>
              </div>

              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaBuilding className="services-icon" />
                </motion.div>

                <h3>UI/UX DESIGN</h3>
                <p>
                  We design visually appealing and user-friendly
                  interfaces, ensuring an intuitive and seamless user
                  experience. You can share you design requirements with us and we will build whatever you have envisioned to build for yourself.
                </p>
              </div>
              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaMobileAlt className="services-icon" />
                </motion.div>

                <h3> MOBILEAPP DEVELOPMENT</h3>
                <p>
                  We build Mobile apps using a range of technologies like Swift (iOS), Kotlin (Android), or Flutter/React Native. Key tools: Xcode, Android Studio. We go with the client requirement, whatever they prefer to be used for their mobile apps.
                </p>
              </div>
            </div>
            <div className="section-services">
              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaWordpress className="services-icon" />
                </motion.div>

                <h3>WORDPRESS</h3>
                <p>
                  WordPress CMS is used for creating a variety of websites, We offer a
                  wide range of services including theme customization and plugins customization and even building themes and plugins from ground up. You can share your task list, Our team will do a task of your choice before even hiring us officially to show our commitment to our clients.
                </p>
              </div>
              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaChrome className="services-icon" />
                </motion.div>

                <h3>CROME EXTENSION</h3>
                <p>
                  Chrome extensions are built using javascript mainly, but more robust extension requires backend server side support which we build using NodeJs, PHP or Python. We can build any kind of chrome extension that is practically possible, we usually create demo before official contract is signed to gather custom trust and confidance in the viability of the project.
            </p>  </div>
              <div className="services-box">
                <motion.div
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <FaReact className="services-icon" />
                </motion.div>

                <h3>REACT JS</h3>
                <p>
                  We can build pixel perfect responsive designs with ReactJS, if you got a design source in figma or other design source file, we will do a page free of cost to show you a demo of our skills, We are really committed to provide quality stuff thats why we offer NO Win NO FEE services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
