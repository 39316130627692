import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Slider.css";
import slider1 from "../../Assests/Img/profile_logo_13237655.webp";
import slider2 from "../../Assests/Img/k.webp";
import slider3 from "../../Assests/Img/SARA.webp";
import slider4 from "../../Assests/Img/r.webp";
import slider5 from "../../Assests/Img/chirles.webp";

// import required modules
import {Autoplay, Navigation, Pagination } from "swiper/modules";

export const Slider = () => {
  return (
    <>
      {" "}
      <div className="slider-container">
        <div className="slider-content">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <div className="slider">
                <div className="slider-img">
                  <img src={slider1} alt="" />
                </div>

                <div>
                  <h3>Joseph B</h3>
                  <h4>Koonawarra, Australia</h4>
                  <p>
                    Shah’s skills with diagnosing Joomla website issues are
                    great, he estimated his work on our project within a
                    reasonable budget too.
                  </p>
                  <div className="slider-btn">
                    <a
                      href="https://www.freelancer.com/u/softsolution2000"
                      target="blank"
                    >
                      <button>VERIFIELD IT</button>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="slider">
                <div className="slider-img">
                  <img src={slider3} alt="" />
                </div>
                <h3>Sara D.</h3>
                <h4>Ramat Gan, Israel</h4>
                <p>
                  Shah is professional, polite and patient. He is reliable and
                  knows his job. I will hire him again for sure. Thank you Shah.
                </p>
                <div className="slider-btn">
                  <a
                    href="https://www.freelancer.com/u/softsolution2000"
                    target="blank"
                  >
                    <button>VERIFIELD IT</button>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="slider">
                <div className="slider-img">
                  <img src={slider5} alt="" />
                </div>
                <h3>Douglas O.</h3>
                <h4>Goodwood, Canada</h4>
                <p>
                  Shah from softsolutions2000 has been great to work with. He was a good communicator and delivered my project. Thank-you Shah for being a professional.
                </p>
                <div className="slider-btn">
                  <a
                    href="https://www.freelancer.com/u/softsolution2000"
                    target="blank"
                  >
                    <button>VERIFIELD IT</button>
                  </a>
                </div>
              </div>
               
            </SwiperSlide>
            <SwiperSlide>
            <div className="slider">
                <div className="slider-img">
                  <img src={slider4} alt="" />
                </div>
                <h3>Berend V.</h3>
                <h4>Arnhem, Germany</h4>
                <p>
                The WOO-Commerce plugin works well, we are very satisfied with the result. The communication was smooth and professional.
                </p>
                <div className="slider-btn">
                  <a
                    href="https://www.freelancer.com/u/softsolution2000"
                    target="blank"
                  >
                    <button>VERIFIELD IT</button>
                  </a>
                </div>
              </div>
               
            </SwiperSlide>
            <SwiperSlide>
            <div className="slider">
                <div className="slider-img">
                  <img src={slider2} alt="" />
                </div>
                <h3>Jeremiah S.</h3>
                <h4>San Antonio, United States</h4>
                <p>
                We have done many projects with TechFas. Very fast, very high trained team. Very good at fixing problems promptly. 
                </p>
                <div className="slider-btn">
                  <a
                    href="https://www.freelancer.com/u/softsolution2000"
                    target="blank"
                  >
                    <button>VERIFIELD IT</button>
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}
