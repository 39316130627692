import React from "react";
import "./Weare.css";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Weare = () => {
  return (
    <>
      <div className="weare-container" id="weare">
        <div className="weare-content">
          <div className="weare-data">
            <motion.h1
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                x: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              WHO WE ARE?
            </motion.h1>
            <hr className="hr" />
            <h2>We are a passionate experienced team with big ambitions.</h2>
            <p>
              Specialising in customer experiences & brand development, we
              combine digital craftsmanship with innovative thinking to deliver
              disruptive digital solutions on a worldwide scale. Specialising in
              customer experiences & brand development, we combine digital
              craftsmanship with innovative thinking to deliver disruptive
              digital.
            </p>
            <p>
              We specialize in Wordpress, Joomla, Laravel, Codigniter, Flutter,
              Chrome Extensions, Automation tools.
            </p>
            <p>
              Drop us your idea and we will work on a demo for you to check the
              vaiability of your idea, we will not charge anything for this.
              Once we should a working demo then we can have a formal contract.
            </p>
            <Link
               className={'contactNow'}
              spy={true}
              smooth={true}
              duration={1000}
              to="contactus"
            >
             <h3 >Don't hesitate, Start Right Now!</h3> 
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Weare;
