import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "./Email.css"

const Email = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const form = useRef();
  const [verified, setVerified] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    const formData = form.current;

    if (!formData.from_name.value.trim()) {
      errors.from_name = "Name is required.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.from_email.value.trim()) {
      errors.from_email = "Email is required.";
    } else if (!emailRegex.test(formData.from_email.value)) {
      errors.from_email = "Invalid email address.";
    }

    if (!formData.message.value.trim()) {
      errors.message = "Message is required.";
    }

    return errors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && verified) {
      emailjs
        .sendForm(
          "service_mpk6erp",
          "template_xel46ji",
          form.current,
          "16s91iEMKpT0EpjuD"
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsEmailSent(true);
        })
        .catch((error) => {
          console.error("FAILED...", error);
          alert("Email sending failed. Please try again later.");
        });
    } else {
      console.error(
        "Form validation failed or reCAPTCHA not verified. Email not sent."
      );
      if (!verified) alert("Please verify that you are not a robot.");
    }
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setVerified(true);
  };

  return (
    <div className="email-container">
      <div className="email-data">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            placeholder="Your Name*"
            name="from_name"
            required
            className={errors.from_name ? "error" : ""}
          />
          {errors.from_name && <div className="error-message">{errors.from_name}</div>}

          <input
            type="email"
            placeholder="Email*"
            name="from_email"
            required
            className={errors.from_email ? "error" : ""}
          />
          {errors.from_email && <div className="error-message">{errors.from_email}</div>}

          <br />
          <textarea
            name="message"
            cols="30"
            rows="10"
            placeholder="Your Message"
            required
            className={errors.message ? "error" : ""}
          ></textarea>
          {errors.message && <div className="error-message">{errors.message}</div>}

          <br />
          <ReCAPTCHA
            className="recap"
            sitekey="6LfH8SIqAAAAAFlkWsbTNzo-uXFWsqFR0mST9IOX"
            onChange={onChange}
          />
          <br />

          <div style={{ textAlign: "center" }}>
            <div className="Email-btn">
            <button
              type="submit"
              value="Send"
              disabled={!verified}
              style={{ marginBottom: "20px" }}
            >
              Send
            </button>
            </div>
           

            {isEmailSent && (
              <div className="success-message">
                <h2>Success!</h2>
                <p>Your email has been sent successfully. Thank you for reaching out!</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Email;
