import React from "react";
import "./Footer.css";
import img1 from "../../Assests/Img/logo.webp";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-data">
        <div className="footer-content">
          <div className="footer-img">
            <img src={img1} alt="" />
          </div>
          <div className="footer-paragraph">
            <p>
              At M/S Techfas, we specialise in top-notch web development
              tailored to your needs. With our commitment to excellence, we
              offer a money-back guarantee and a "no win, no fee" promise. This
              means you pay only for results—if we don’t deliver, you don’t pay.
              Partner with us for innovative, reliable web solutions and
              experience peace of mind knowing that your satisfaction is
              our top priority.
            </p>
          </div>
          <div className="footer-info">
            <p>
              <span>Phone: +92 344 4546731</span>
            </p>
            <p>
              <span>Email: info@techfas.com</span>
            </p>
            <p>
              <span>Website: www.techfas.com</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
