import img1 from "../../Assests/Img/bhijan.webp";
import img2 from "../../Assests/Img/layer-22.webp";
import img3 from "../../Assests/Img/kas.webp";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/pagination";

import "./Banner.css";
import { motion } from "framer-motion";
// import required modules
import { Autoplay} from "swiper/modules";
import React from "react";

const Banner = () => {
  return (
    <>
      <Swiper
        pagination={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="banner-content">
            <div className="head">
              <div className="head-text">
                <motion.h1
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  No Win No FEE
                </motion.h1>
                <motion.h4
                  initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 50,
                    rotate: -10,
                    textShadow: "0px 0px 0px rgba(255, 255, 255, 0.5)",
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    rotate: 0,
                    textShadow: "0px 0px 10px rgba(255, 255, 255, 0.7)",
                    transition: {
                      opacity: { duration: 1, ease: "easeOut" },
                      scale: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 200,
                        damping: 10,
                      },
                      y: { type: "spring", stiffness: 100, damping: 15 },
                      rotate: { duration: 0.5 },
                      textShadow: { duration: 0.8, ease: "easeInOut" },
                    },
                  }}
                >
                 Pay upon your 100% satisfaction!
                </motion.h4>
              </div>
              <div className="head-row">
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="info"
                >
                  <div className="kas-img">
                    <img src={img1} alt="" className="shah" />
                  </div>
                  <div className="sig-row">
                    <div>
                      <h2>SHAH KHALID </h2>
                      <h3>CEO</h3>
                      <p>
                        "Your goal is over goal, We love to take you there!"{" "}
                      </p>
                    </div>
                    <div>
                      <img src={img2} alt="" className="sig" />
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="banner-content1">
            <div className="head1">
              <div className="head-text">
                <motion.h3
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  We'll assess your goal's viability
                </motion.h3>
                <motion.h5
                  initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 50,
                    rotate: -10,
                    textShadow: "0px 0px 0px rgba(255, 255, 255, 0.5)",
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    rotate: 0,
                    textShadow: "0px 0px 10px rgba(255, 255, 255, 0.7)",
                    transition: {
                      opacity: { duration: 1, ease: "easeOut" },
                      scale: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 200,
                        damping: 10,
                      },
                      y: { type: "spring", stiffness: 100, damping: 15 },
                      rotate: { duration: 0.5 },
                      textShadow: { duration: 0.8, ease: "easeInOut" },
                    },
                  }}
                >
                  100% Free of cost.
                </motion.h5>
              </div>
              <div className="head-row">
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: { type: "spring", stiffness: 60 },
                    opacity: { duration: 1 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="info"
                >
                  <div className="kas-img">
                    <img src={img3} alt="" className="shah" />
                  </div>
                  <div className="sig-row">
                    <div>
                      <h2>KASHIF ULLAH </h2>
                      <h3>ReactJs Lead</h3>
                      <p>
                        "Our goals is to help your business grow through prims"{" "}
                      </p>
                    </div>
                    <div>
                      <img src={img2} alt="" className="sig" />
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Banner;
