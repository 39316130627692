import Banner from "./Components/Banner/Banner";
import Business from "./Components/Business/Business";
import Dateandtime from "./Components/DateandTime/Dateandtime";
import Email from "./Components/Email/Email";
import Footer from "./Components/Footer/Footer";
import Form from "./Components/Form/Form";
import Gototop from "./Components/GotoTop/Gototop";
import Icon from "./Components/Icons/Icon";
import Navbar from "./Components/Navbar/Navbar";
import Team from "./Components/Ourteam/Team";
import Projects from "./Components/Projects/Projects";
import Services from "./Components/Services/Services";
import { Slider } from "./Components/Slider/Slider";
import Weare from "./Components/Who WE ARE/Weare";
//import WhatsAppWidget from "./Components/whatsapp/WhatsAppWidget";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
function App() {
  return (
    <div>
      <Navbar />
      <Banner />
      <Weare/>
      <Services/>
      <Projects/>
      <Business/>
      <Team/>
      <Icon/>
      <Slider/>
      <Form/>
      <Gototop/>
      <Email/>
      <Footer/>
      <Dateandtime/>
      <FloatingWhatsApp phoneNumber="923444546731" accountName="Techfas.com Team" statusMessage="Typically replies within a few mins" />
      
    </div>
  );
}

export default App;
