import React from "react";
import "./Dateandtime.css";

const Dateandtime = () => {
  return (
    <>
      <div className="main">
        <div className="date">
          <h1 className="h1">© Copyrights 2024</h1>
        </div>
      </div>
    </>
  );
};

export default Dateandtime;
