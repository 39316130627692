import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-scroll";
import logo from "../../Assests/Img/logo.webp";


function Navbar() {
    const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  window.addEventListener("scroll", changeColor);
  return (
    <div className={color ? "nav nav-bg" : "nav"}>
<div className="nav-content">
      <nav className="navbar">
        <div className="logo"> <img src={logo} alt="logo" /></div>
        <div
          className={`menu-icon ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li>
              <a href="%">
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="weare"
                  activeClassName="selected"
                >
                  WHO WE ARE
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a href="%">
                {" "}
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="services"
                  activeClassName="selected"
                >
                  OUR SERVICES
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a href="%">
                {" "}
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="projects"
                  activeClassName="selected"
                >
                  PROJECTS
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a href="%">
                {" "}
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="team"
                  activeClassName="selected"
                >
                  OUR TEAM
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a href="%">
                {" "}
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="clients"
                  activeClassName="selected"
                >
                  CLIENTS
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a href="%">
                {" "}
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="contactus"
                  activeClassName="selected"
                >
                  CONTACT US
                </Link>
              </a>
            </li>
        </ul>
      </nav>
    </div>
    </div>
     
  );
}

export default Navbar;
