import React, { useState, useRef,forwardRef } from "react";
import { FaMoneyCheckDollar, FaFish, FaSquareCheck } from "react-icons/fa6";
import line from "../../Assests/Img/line-2.webp";
import "./Icon.css";
import CountUp from "react-countup";
import Scrolltrigger from "react-scroll-trigger";

const Icon = () => {
  const ForwardRefCountUp = forwardRef((props, ref) => {
    return <CountUp ref={ref} {...props} />;
  });
  const [countrtOn, setCounterOn] = useState(false);
  const countUpRef1 = useRef(null);
  const countUpRef2 = useRef(null);
  const countUpRef3 = useRef(null);

  return (
    <>
      <div className="icons-container" id="clients">
        <div className="icons-contents">
          <div className="icons-data">
            <FaSquareCheck className="icon" />
            <Scrolltrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {countrtOn && (
                 <ForwardRefCountUp
                 ref={countUpRef1}
                 start={0}
                 end={1227}
                 duration={2}
                 delay={0}
                 style={{
                   color: "white",
                   lineHeight: "69px",
                   fontSize: "36px",
                   fontWeight: "700",
                 }}
               />
              )}
            </Scrolltrigger>
            <h2>Projects done</h2>
          </div>
          <div className="img">
            <img src={line} alt="" />
          </div>
          <div className="icons-data">
            <FaMoneyCheckDollar className="icon" />
            <Scrolltrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {countrtOn && (
                    <ForwardRefCountUp
                    ref={countUpRef1}
                    start={0}
                    end={2000000}
                    duration={2}
                    delay={0}
                    style={{
                      color: "white",
                      lineHeight: "69px",
                      fontSize: "36px",
                      fontWeight: "700",
                    }}
                  />
              )}
            </Scrolltrigger>
            <h2>we helped earn our clients</h2>
          </div>
          <div className="img">
            <img src={line} alt="" />
          </div>
          <div className="icons-data">
            <FaFish className="icon" />
            <Scrolltrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {countrtOn && (
                     <ForwardRefCountUp
                     ref={countUpRef1}
                     start={0}
                     end={432}
                     duration={2}
                     delay={0}
                     style={{
                       color: "white",
                       lineHeight: "69px",
                       fontSize: "36px",
                       fontWeight: "700",
                     }}
                   />
              )}
            </Scrolltrigger>
            <h2>BBQ Parties</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Icon;
